import React, {useContext, useRef} from 'react'
import * as XLSX from 'xlsx'
import { 
  PRODUCT_IMPORT_TYPE_ACCESSORY,
  PRODUCT_IMPORT_TYPE_BEAM,
  PRODUCT_IMPORT_TYPE_FIXING,
  PRODUCT_IMPORT_TYPE_FLASHING,
  PRODUCT_IMPORT_TYPE_LIGHT,
  PRODUCT_IMPORT_TYPE_PANEL,
  PRODUCT_IMPORT_TYPE_POST,
  PRODUCT_IMPORT_TYPE_RAINWATER 
} from '../../../../../common/constant'
import {
  transformBeamsSaveJson,
  transformFixingsSaveJson,
  transformFlashingsSaveJson,
  transformLightsSaveJson,
  transformPanelSaveJson,
  transformPostsSaveJson,
  transformRainwatersSaveJson,
  transformAccessoriesSaveJson
} from './transformer'
import {saveProductMaster, getImportProgress} from '../../../../../api/productmaster-api'
import {AdminContext} from '../../../../../context/AdminContext'

const UploadProductMaster = () => {
  const {setMessage, isUploading, setIsUploading, pollProgress, stopProgress, setProgress} =
    useContext(AdminContext)
  const fileInputRef = useRef<any>(null)

  const handleFileChange = (e: any) => {
    const file = e.target.files[0]
    // startProgress()
    // Validate file type (allow only XLS or XLSX)
    if (
      file &&
      (file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    ) {
      // setSelectedFile(file)
      setIsUploading(true)
      convertExcelToJson(file)
        .then((jsonData) => {
          setProgress(6)
          handleUpload(jsonData)
        })
        .catch((error) => {
          setIsUploading(false)
          stopProgress()
          setMessage({
            isSuccess: false,
            message: error,
          })
          // setTimeout(() => {
          //   setMessage(null)
          // }, 5000)
        })
    } else {
      setMessage({
        isSuccess: false,
        message: 'Please upload a valid Excel file (XLS or XLSX).',
      })
      // Clear the input to allow selecting a new file
      e.target.value = null
      stopProgress()
    }
  }

  const convertExcelToJson = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (e: any) => {
        try {
          const data = e.target.result
          const workbook = XLSX.read(data, {type: 'binary'}) as any
          const jsonData = {} as any
          let hasNoHeader = false

          if (workbook.SheetNames.length <= 1) {
            reject('Please upload a valid import file.')
            return
          }

          workbook.SheetNames.forEach((sheetName: any) => {
            var xlsJson: any = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1})
            if (PRODUCT_IMPORT_TYPE_PANEL.indexOf(sheetName.toLowerCase()) >= 0) {
              jsonData[PRODUCT_IMPORT_TYPE_PANEL] = transformPanelSaveJson(xlsJson)
            }
            if (PRODUCT_IMPORT_TYPE_FIXING.indexOf(sheetName.toLowerCase()) >= 0) {
              jsonData[PRODUCT_IMPORT_TYPE_FIXING] = transformFixingsSaveJson(xlsJson)
            }
            if (PRODUCT_IMPORT_TYPE_FLASHING.indexOf(sheetName.toLowerCase()) >= 0) {
              var flashings = transformFlashingsSaveJson(xlsJson)
              jsonData[PRODUCT_IMPORT_TYPE_FLASHING] = flashings.map((flashing: any) => ({
                ...flashing,
                priceLevel1: Number.isNaN(parseInt(flashing.priceLevel1, 10))
                  ? 0
                  : flashing.priceLevel1,
                priceLevel2: Number.isNaN(parseInt(flashing.priceLevel2, 10))
                  ? 0
                  : flashing.priceLevel2,
                priceLevel3: Number.isNaN(parseInt(flashing.priceLevel3, 10))
                  ? 0
                  : flashing.priceLevel3,
                priceLevel4: Number.isNaN(parseInt(flashing.priceLevel4, 10))
                  ? 0
                  : flashing.priceLevel4,
                priceLevel5: Number.isNaN(parseInt(flashing.priceLevel5, 10))
                  ? 0
                  : flashing.priceLevel5,
                priceLevel6: Number.isNaN(parseInt(flashing.priceLevel6, 10))
                  ? 0
                  : flashing.priceLevel6
              }))
            }
            if (PRODUCT_IMPORT_TYPE_RAINWATER.indexOf(sheetName.toLowerCase()) >= 0) {
              jsonData[PRODUCT_IMPORT_TYPE_RAINWATER] = transformRainwatersSaveJson(xlsJson)
            }
            if (PRODUCT_IMPORT_TYPE_BEAM.indexOf(sheetName.toLowerCase()) >= 0) {
              jsonData[PRODUCT_IMPORT_TYPE_BEAM] = transformBeamsSaveJson(xlsJson)
            }
            if (PRODUCT_IMPORT_TYPE_POST.indexOf(sheetName.toLowerCase()) >= 0) {
              jsonData[PRODUCT_IMPORT_TYPE_POST] = transformPostsSaveJson(xlsJson)
            }
            if (PRODUCT_IMPORT_TYPE_LIGHT.indexOf(sheetName.toLowerCase()) >= 0) {
              jsonData[PRODUCT_IMPORT_TYPE_LIGHT] = transformLightsSaveJson(xlsJson)
            }
            if (PRODUCT_IMPORT_TYPE_ACCESSORY.indexOf(sheetName.toLowerCase()) >= 0) {
              jsonData[PRODUCT_IMPORT_TYPE_ACCESSORY] = transformAccessoriesSaveJson(xlsJson)
            }

            if (sheetName.toLowerCase().indexOf('pricing') < 0) {
              if (xlsJson.length > 0 && xlsJson[0].length <= 0 && !hasNoHeader) {
                reject('Please upload a valid import file.')
              }
              if (xlsJson.length === 0) {
                reject('Please upload a valid import file.')
              }
            }
          })

          resolve(jsonData)
        } catch (error) {
          reject(error)
        }
      }

      reader.readAsBinaryString(file)
    })
  }

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleUpload = (jsonData: any) => {
    saveProductMaster(jsonData)
      .then((result: any) => {
        if (result.status === 409) {
          setMessage({
            isSuccess: false,
            message: 'An import is already in progress. Please wait for it to complete.',
          })
        } else if (result.data) {
          const taskId = result.data
          pollProgress(taskId)
        } else {
          setMessage({
            isSuccess: false,
            message: 'Failed to Upload Product Master List',
          })
          stopProgress()

          setIsUploading(false)
          // setTimeout(() => {
          //   setMessage(null)
          // }, 5000)
        }
      })
      .catch(() => {
        setIsUploading(false)
        stopProgress()
        setMessage({
          isSuccess: false,
          message: 'Failed to Upload Product Master List',
        })
        // setTimeout(() => {
        //   setMessage(null)
        // }, 5000)
      })
  }

  return (
    <div>
      <input
        type='file'
        accept='.xls, .xlsx'
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{display: 'none'}}
        disabled={isUploading}
      />
      <button disabled={isUploading} type='button' className='btn btn-primary' onClick={handleButtonClick}>
        IMPORT
      </button>
    </div>
  )
}

export default UploadProductMaster
