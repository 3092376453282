import React, {FC, useState, lazy, useContext, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_metronic/layout/core'
import clsx from 'clsx'
import Panels from './Panels/Panels'
import Beams from './Beams/Beams'
import Fixings from './Fixings/Fixings'
import Flashings from './Flashings/Flashings'
import Lights from './Lights/Lights'
import Posts from './Posts/Posts'
import Rainwater from './RainWater/Rainwater'
import Accessory from './Accessory/Accessory'
import {AdminProductContextProvider} from '../../../../context/AdminProductContext'
import UploadProductMaster from '../ProductMaster/Upload/UploadProductMaster'
import {saveProductMaster} from '../../../../api/productmaster-api'
import {UploadLoader} from '../ProductMaster/Loader/UploadLoader'
import XLSX from 'xlsx'

import {
  getAllBeamProductForExport,
  getAllFixingProductForExport,
  getAllFlashingProductForExport,
  getAllLightProductForExport,
  getAllPanelProductForExport,
  getAllPostProductForExport,
  getAllRainwaterProductForExport,
  getAllAccessoryProductForExport
} from '../../../../api/index'
import {AdminContext} from '../../../../context/AdminContext'


const Product = () => {
  const XLSX = require('xlsx')
  const axios = require('axios')

  const intl = useIntl()

  const [tab, setTab] = useState('Panels')
  const [product, setProduct] = useState('SEQ Pricebook')
  const [productId, setProductId] = useState(1)

  const {setIsUploading, isUploading, setProgress, pollProgress, activeTaskId, pollActiveImport} = useContext(AdminContext)

  useEffect(() => {
    if (!isUploading) pollActiveImport();

    if (!isUploading) {
      const intervalId = setInterval(() => {
        pollActiveImport();
      }, 5000);
  
      return () => clearInterval(intervalId);
    }
  }, [isUploading]);
  

  useEffect(() => {
    if (isUploading) {
        console.log(`Import in progress: Task ID ${activeTaskId}`);
    }
  }, [isUploading, activeTaskId]);

  const handleProductOnChange = (e: any) => {
    setProduct(e.target.value)
    if (e.target.value == 'SEQ Pricebook') setProductId(1)
    else setProductId(2)
  }

  const handleImport = () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.xlsx'

    fileInput.addEventListener('change', (event) => {
      const selectedFile = (event.target as HTMLInputElement).files?.[0]

      if (selectedFile) {
        console.log('Selected File:', selectedFile)
      }
    })

    fileInput.click()
  }

  const formatDate = (date: Date) => {
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yy = String(date.getFullYear()).slice(-2)
    return `${dd}.${mm}.${yy}`
  }

  type ProductData = {
    id: number
    panelProfile?: string
    panelCore?: string
    thickness?: string
    topSheetColour?: string
    bottomSheetColour?: string
    screwName?: string
    screwType?: string
    flashingName?: string
    panelType?: string
    colour?: string
    productName?: string
    gutterType?: string
    beamProduct?: string
    type?: string
    postType?: string
  }

  function formatFieldName(fieldName: any) {
    return (
      fieldName
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        .charAt(0)
        .toUpperCase() + fieldName.slice(1)
    )
  }
  type ApiResponse = Record<string, any>
  const [exportProgress, setExportProgress] = useState(0)

  const generateExcel = async () => {
    const workbook = XLSX.utils.book_new()
    const totalApis = 7
    setExportProgress(10)
  
    const fetchData = async (apiFunction: () => Promise<{ data: ApiResponse[], headers: Record<string, string> }>, sheetName: string): Promise<void> => {
      try {
        const response = await apiFunction()
  
        if (!response.data || response.data.length === 0) {
          console.error(`No data returned for ${sheetName}.`)
          return
        }
  
        const headers = response.headers
        const headerRow = Object.values(headers)
  
        const sheetData = [
          headerRow,
          ...response.data.map(item =>
            headerRow.map(header => {
              const key = Object.keys(headers).find(key => headers[key].toLowerCase() === header.toLowerCase())
              const value = key ? item[key] : ''

              if (value === null || value === undefined) {
                return value
              } else if (typeof value === 'number') {
                return value
              } else {
                return String(value)
              }
            })
          )
        ]
  
        const sheet = XLSX.utils.aoa_to_sheet(sheetData)
        XLSX.utils.book_append_sheet(workbook, sheet, sheetName)
        setExportProgress(prevProgress => prevProgress + (100 / totalApis))
      } catch (error) {
        console.error(`Error processing ${sheetName}:`, error)
      }
    }
  
    const apis = [
      getAllPanelProductForExport,
      getAllFixingProductForExport,
      getAllFlashingProductForExport,
      getAllRainwaterProductForExport,
      getAllBeamProductForExport,
      getAllPostProductForExport,
      getAllAccessoryProductForExport,
      getAllLightProductForExport,
    ]
  
    const sheetNames = [
      'Panel',
      'Fixings',
      'Flashings',
      'Rainwater',
      'Beam',
      'Posts',
      'Accessories',
      'Lights',
    ]
  
    for (let i = 0; i < apis.length; i++) {
      await fetchData(apis[i], sheetNames[i])
    }
  
    const dateStr = formatDate(new Date())
    const fileName = `Delta Products MasterList_${dateStr}.xlsx`
    XLSX.writeFile(workbook, fileName)
    setExportProgress(100)
    console.log(`Excel file "${fileName}" generated successfully!`)
  }  
  
  const handleExport = () => {
    generateExcel()
  }

  interface ExportProgressBarProps {
    progress: number
  }
  
  const ExportProgressBar: React.FC<ExportProgressBarProps> = ({ progress }) => {
    return progress < 100 ? (
      <div className='col-8'>
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            {progress.toFixed(2)}% Complete
          </div>
        </div>
      </div>
    ) : null;
  };

  let content

  switch (tab) {
    case 'Panels':
      content = <Panels product={product} productId={productId} />
      break
    case 'Beams':
      content = <Beams product={product} productId={productId} />
      break
    case 'Fixings':
      content = <Fixings product={product} productId={productId} />
      break
    case 'Flashings':
      content = <Flashings product={product} productId={productId} />
      break
    case 'Lights':
      content = <Lights product={product} productId={productId} />
      break
    case 'Posts':
      content = <Posts product={product} productId={productId} />
      break
    case 'Rainwater':
      content = <Rainwater product={product} productId={productId} />
      break
    case 'Accessory':
        content = <Accessory product={product} productId={productId} />
        break
    default:
      content = <Beams product={product} productId={productId} />
      break
  }

  const handleUpload = (jsonData: any) => {
    saveProductMaster(jsonData).then((result: any) => {
      if (result.data) {
      } else {
      }
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PRODUCTMASTERLIST'})}</PageTitle>
      <UploadLoader></UploadLoader>
      <ExportProgressBar progress={exportProgress} />
      {/* BUTTONS */}
      <div className='mb-5'>
        <div className='d-flex justify-content-end'>
            <UploadProductMaster></UploadProductMaster>
          <div className='ms-2'></div>
          <button disabled={isUploading} className='btn btn-primary' onClick={handleExport}>
            EXPORT
          </button>
        </div>
      </div>
      {/*  */}

      <div className='header-menu align-items-stretch'>
        <div className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'>
          <div className='menu-item me-lg-1'>
            <div
              role='tab'
              onClick={() => setTab('Panels')}
              className={clsx('menu-link py-3', {
                active: tab === 'Panels',
              })}
            >
              <span className='menu-title'>Panels</span>
            </div>
          </div>

          <div className='menu-item me-lg-1'>
            <div
              role='tab'
              onClick={() => setTab('Fixings')}
              className={clsx('menu-link py-3', {
                active: tab === 'Fixings',
              })}
            >
              <span className='menu-title'>Fixings</span>
            </div>
          </div>
          <div className='menu-item me-lg-1'>
            <div
              role='tab'
              onClick={() => setTab('Flashings')}
              className={clsx('menu-link py-3', {
                active: tab === 'Flashings',
              })}
            >
              <span className='menu-title'>Flashings</span>
            </div>
          </div>
          <div className='menu-item me-lg-1'>
            <div
              role='tab'
              onClick={() => setTab('Rainwater')}
              className={clsx('menu-link py-3', {
                active: tab === 'Rainwater',
              })}
            >
              <span className='menu-title'>Rainwater</span>
            </div>
          </div>

          <div className='menu-item me-lg-1'>
            <div
              role='tab'
              onClick={() => setTab('Beams')}
              className={clsx('menu-link py-3', {
                active: tab === 'Beams',
              })}
            >
              <span className='menu-title'>Beams</span>
            </div>
          </div>

          <div className='menu-item me-lg-1'>
            <div
              role='tab'
              onClick={() => setTab('Posts')}
              className={clsx('menu-link py-3', {
                active: tab === 'Posts',
              })}
            >
              <span className='menu-title'>Posts</span>
            </div>
          </div>

          <div className='menu-item me-lg-1'>
            <div
              role='tab'
              onClick={() => setTab('Accessory')}
              className={clsx('menu-link py-3', {
                active: tab === 'Accessory',
              })}
            >
              <span className='menu-title'>Accessories</span>
            </div>
          </div>

          <div className='menu-item me-lg-1'>
            <div
              role='tab'
              onClick={() => setTab('Lights')}
              className={clsx('menu-link py-3', {
                active: tab === 'Lights',
              })}
            >
              <span className='menu-title'>Lights</span>
            </div>
          </div>
        </div>
      </div>
      {/* CONTENT */}
      <AdminProductContextProvider>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='tab-pane'>{content}</div>
        </div>
      </AdminProductContextProvider>
    </>
  )
}

export default Product
