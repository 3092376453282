import axios from 'axios'

export const PRODUCTMASTER_URL = `${process.env.REACT_APP_API_URL}/productmaster`
export const saveProductMaster = async (productList: any) => {
  try {
    return await axios.post(`${PRODUCTMASTER_URL}`, productList)
  } catch (err) {
    console.log('err', err)
    return err
  }
}

export const getImportProgress = async (taskId: string) => {
  try {
    return await axios.get(`${PRODUCTMASTER_URL}/progress`, {
      params: { taskId },
    })
  } catch (err) {
    return err
  }
}

export const getActiveImport = async () => {
  return await axios.get(`${PRODUCTMASTER_URL}/active-import`)
}