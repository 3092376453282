import {useContext, useEffect, useState} from 'react'
import {AdminContext} from '../../../../../context/AdminContext'
import React from 'react'
let progressInterval: any
const UploadLoader = () => {
  const {message, progress, setMessage} = useContext(AdminContext)
  return (
    <React.Fragment>
      {message ? (
        <div
          className={`alert ${message.isSuccess ? 'alert-info' : 'alert-danger'} w-25 m-auto`}
          role='alert'
        >
          <div className='d-flex'>
            <div
              className='p-2 text-center'
              style={{
                width: '95%',
              }}
            >
              {message.message}
            </div>
            <div
              className='ml-auto p-2'
              style={{
                cursor: 'pointer', // Default cursor style
                transition: 'cursor 0.3s', // Add a smooth transition for better user experience
              }}
              onClick={() => {
                setMessage(null)
              }}
            >
              <span aria-hidden='true'>&times;</span>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-column w-100 mb-12'>
          <div className='progress h-5px w-100'>
            <div
              className={`progress-bar progress-bar-striped progress-bar-animated `}
              role='progressbar'
              style={{width: progress + '%'}}
            ></div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
export {UploadLoader}
