import React, {useState} from 'react'
import { getPriceBooks } from '../api/pricebook'
import { useAsyncFn } from 'react-use'
import { getImportProgress, getActiveImport } from '../api/productmaster-api'
import { debug } from 'console'

export const AdminContext = React.createContext<any>({})

let progressInterval: any
export const AdminContextProvider: React.FC = ({children}) => {
  const [message, setMessage] = useState<any>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [activeTaskId, setActiveTaskId] = useState<string | null>(null)

  const pollActiveImport = async () => {
    const response = await getActiveImport()
    if (response?.data) {
      setIsUploading(true)
      setProgress(
        response.data.currentStep === 0 
          ? 6 
          : (response.data.currentStep / response.data.totalSteps) * 100
      )
      pollProgress(response.data.taskId)
    } else {
      setIsUploading(false)
      setActiveTaskId(null)
    }
  }
  

  const pollProgress = (taskId: string) => {
    const intervalId = setInterval(async () => {
      const response: any = await getImportProgress(taskId)
      setActiveTaskId(taskId)
      if (response?.data) {
        setProgress(
          response.data.currentStep === 0 
            ? 6 
            : (response.data.currentStep / response.data.totalSteps) * 100
        )

        if (response.data.status === "Completed") {
          clearInterval(intervalId)
          setMessage({ isSuccess: true, message: 'Product Master List Uploaded Successfully' })
          setIsUploading(false)
          stopProgress()
        } else if (response.data.status === "Failed") {
          clearInterval(intervalId);
          setMessage({ isSuccess: false, message: 'Product Master List Upload Failed' })
          setIsUploading(false);
          stopProgress();
        }
      }
    }, 3000)
  }

  const startProgress = () => {
    if (progressInterval) {
      setProgress(6)
      clearInterval(progressInterval)
    }
    setMessage(null)
    progressInterval = setInterval(() => {
      // Your action or function to be executed at regular intervals
      if (progress > 90) return
      setProgress((prevCounter: any) => prevCounter + 3)
    }, 3000) // Ad
  }

  const stopProgress = () => {
    if (progressInterval) {
      clearInterval(progressInterval)
    }
    setProgress(0)

    // setTimeout(() => {
    //   setProgress(0)
    // }, 1000)
  }

   // ** Unit Type **//
   const [priceBooks, setPriceBooks] = useState<[] | undefined>()
   const [{ loading: fetchPricebook }, getPriceBooksAsync] = useAsyncFn(async () => {
       const result: any = await getPriceBooks()
       if (result != null) {
           setPriceBooks(result.data)
       }
   }, [setPriceBooks])
   

  return (
    <AdminContext.Provider
      value={{
        message,
        setMessage,
        isUploading,
        setIsUploading,
        progress,
        setProgress,
        startProgress,
        stopProgress,
        priceBooks, 
        getPriceBooksAsync,
        pollProgress,
        activeTaskId,
        pollActiveImport
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}
