import axios from 'axios'

export const BEAMPRICING_URL = `${process.env.REACT_APP_API_URL}/beampricings`
export const BEAMTYPES_URL = `${process.env.REACT_APP_API_URL}/BeamTypes`
export const BEAMFINISHES_URL = `${process.env.REACT_APP_API_URL}/BeamFinishes`
export const BEAMS_URL = `${process.env.REACT_APP_API_URL}/Beams`
export const BEAMUNITTYPES_URL = `${process.env.REACT_APP_API_URL}/BeamUnitTypes`

export const BEAMMASTERLIST_URL = `${process.env.REACT_APP_API_URL}/BeamMasterlist`
export const BEAMSIZES_URL = `${process.env.REACT_APP_API_URL}/BeamSizes`
export const BEAMPRODUCTS_URL = `${process.env.REACT_APP_API_URL}/BeamProducts`

export const BEAMQUICKORDER_URL = `${process.env.REACT_APP_API_URL}/BeamQuickOrder`
export const BEAMADDPROD_URL = `${process.env.REACT_APP_API_URL}/OrderDetails`

interface BeamProduct {
  id: number
  beamProduct: string
  type: string
  colour: string
}

export const getBeamProducts = async () => {
  try {
    return await axios.get(`${BEAMPRODUCTS_URL}`)
  } catch (err) {
    return []
  }
}

export const getBeamSizes = async () => {
  try {
    return await axios.get(`${BEAMSIZES_URL}`)
  } catch (err) {
    return []
  }
}

export const getAllProduct = async () => {
  try {
      const response = await axios.get(`${BEAMMASTERLIST_URL}/getAllBeamProduct`)
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getBeamMasterlist = async (
  currentPage: number,
  dataPerPage: number,
  BeamProductName?: string,
  BeamSizeName?: string,
  ColourName?: string
) => {
  try {
    return await axios.get(`${BEAMMASTERLIST_URL}`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        BeamProductName,
        BeamSizeName,
        ColourName
      },
    })
  } catch (err) {
    return err
  }
}

export const createBeamProduct = async (payload: any) => {
  try {
    return await axios.post(`${BEAMMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateBeamProduct = async (payload: any) => {
  try {
    return await axios.patch(`${BEAMMASTERLIST_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteBeamProduct = async (id: any) => {
  try {
    return await axios.delete(`${BEAMMASTERLIST_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getBeamPricingsPaginated = async (
  currentPage: number,
  dataPerPage: number,
  priceBookid?: number
) => {
  try {
    return await axios.get(`${BEAMPRICING_URL}/Paginated`, {
      params: {
        PageNumber: currentPage,
        PageSize: dataPerPage,
        priceBookid,
      },
    })
  } catch (err) {
    return err
  }
}

export const getBeamPricings = async (priceBookid?: number) => {
  try {
    return await axios.get(`${BEAMPRICING_URL}`, {
      params: {
        priceBookid,
      },
    })
  } catch (err) {
    return err
  }
}

export const createBeamPricing = async (payload: any) => {
  try {
    return await axios.post(`${BEAMPRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const updateBeamPricing = async (payload: any) => {
  try {
    return await axios.patch(`${BEAMPRICING_URL}`, payload)
  } catch (err) {
    return err
  }
}

export const deleteBeamPricing = async (id: number) => {
  try {
    return await axios.delete(`${BEAMPRICING_URL}?id=${id}`)
  } catch (err) {
    return []
  }
}

export const getBeamTypes = async () => {
  try {
    return await axios.get(`${BEAMTYPES_URL}`)
  } catch (err) {
    return []
  }
}

export const getBeamFinishes = async () => {
  try {
    return await axios.get(`${BEAMFINISHES_URL}`)
  } catch (err) {
    return []
  }
}

export const getBeams = async () => {
  try {
    return await axios.get(`${BEAMS_URL}`)
  } catch (err) {
    return []
  }
}

export const getBeamsPM = async () => {
  try {
    return await axios.get(`${BEAMQUICKORDER_URL}/getAllBeamProducts`)
  } catch (err) {
    return []
  }
}

export const getBeamColours = async (isForBeamEndCap?: boolean, isForBeamFasciaHanging?: boolean, beamId?: number) => {
  try {
    return await axios.get(`${BEAMQUICKORDER_URL}/getAllColours`, {
      params: {
        isForBeamEndCap,
        isForBeamFasciaHanging,
        beamId
      },
    })
  } catch (err) {
    return []
  }
}

export const getBeamUnitPrice = async (beamProductId: number, beamSizeId?: number, colourId?: number, isForBeamFasciaHanging?: boolean) => {
  try {
    const response = await axios.get(`${BEAMQUICKORDER_URL}/getUnitPrice`, {
      params: {
        beamProductId,
        beamSizeId,
        colourId,
        isForBeamFasciaHanging
      },
    })
    return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getBeamProductDetails = async (
  beamProductId: number, 
  beamSizeId: number, 
  colourId?: number) => {
  try {
      const response = await axios.get(`${BEAMADDPROD_URL}/getProductBeamUnitPrice`, {
        params: {
          beamProductId,
          beamSizeId,
          colourId
        },
      })
      return response.data
  } catch (ex) {
      return [null, ex]
  }
}

export const getBeamTypesProdMasterlist = async (isForBeamFasciaHanging?: boolean, beamId?: number) => {
  try {
    return await axios.get(`${BEAMQUICKORDER_URL}/getAllBeamSizes`, {
      params: {
        isForBeamFasciaHanging,
        beamId
      },
    })
  } catch (err) {
    return []
  }
}

export const getBeamUnitTypes = async () => {
  try {
    return await axios.get(`${BEAMUNITTYPES_URL}`)
  } catch (err) {
    return []
  }
}
